@import "reset";
@import "fonts";
@import "colors";
@import "mixins";

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  color: $grey-100;
  background-color: #F6F6F6;
}

a {
  color: $deed-green-100;
  &:visited {
    color: $deed-green-100;
  }
}

.datepicker {
  position: relative;
  img {
    position: absolute;
    left: 12px;
    top: 9px;
    z-index: 1;
  }
  .input {
    padding-left: 42px;
  }
  .react-datepicker {
    @include datepicker-general;
  }
}

.error-label {
  color: #FF6A56;
  font-size: 14px;
}
