// Drop shadows go outwards in ascending order, i.e. level 4 should be on the biggest box, containing the other boxes,
// if box nesting is higher than 4, then no drop shadow. See styleguide: https://www.figma.com/file/YBZJOZgDkIFafyJjBdamA4/Sidekick-design-system?node-id=110%3A40
@mixin drop-shadow-level-1 {
  box-shadow: 0px 1px 2px 1px rgba(7, 45, 51, 0.04);
}

@mixin drop-shadow-level-2 {
  box-shadow: 0px 1px 4px 1px rgba(7, 45, 51, 0.08);
}

@mixin drop-shadow-level-3 {
  box-shadow: 0px 2px 6px 2px rgba(7, 45, 51, 0.12);
}

@mixin drop-shadow-level-4 {
  box-shadow: 0px 4px 8px 2px rgba(0, 16, 51, 0.16);
}

@mixin drop-shadow-inset {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15),
    inset -2px -2px 2px rgba(0, 0, 0, 0.05);
}

@mixin datepicker-general {
  font-family: "Open Sans", sans-serif;
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 16px;
  &.react-datepicker--time-only {
    padding: 8px;
  }
  &-time__header {
    font-size: 14px;
  }
  &__header {
    background: #ffffff;
    border-bottom: none;
  }
  &__month {
    margin: 0;
  }
  &__triangle {
    &:after, &:before {
      display: none;
    }
  }
  &__day {
    width: 36px;
    height: 34px;
    line-height: 34px;
    font-weight: 400;
    margin: 0;
    font-size: 15px;
    color: #2A2A2A;
    &--selected, &--keyboard-selected {
      background: #56CC7E;
      color: #ffffff;
    }
    &-name {
      width: 36px;
      height: 34px;
      line-height: 34px;
      margin: 0;
      font-size: 11px;
      font-weight: 600;
      color: #767676;
    }
    &--outside-month {
      color: #EAEAEA;
    }
  }
  &__current-month {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    padding-bottom: 12px;
  }
  &__navigation {
    top: 23px;
    &--next {
      right: 16px;
    }
    &--previous {
      left: 16px;
    }
  }
  &__navigation-icon {
    &::before {
      width: 10px;
      height: 10px;
      border-color: #767676;
      border-width: 2px 2px 0 0;
    }
  }
  &__time-list-item {
    font-weight: 400;
    height: auto !important;
    border-radius: 4px;
    &--selected {
      background-color: #56ca7d !important;
    }
  }
}
